<template>
  <div
    :class="{ indent, mini, disabled }"
    class="r-ritm-tabs"
  >
    <r-text
      v-if="label"
      color-type="subhead"
    >
      {{ label }}
    </r-text>
    <ul
      :class="{
        mini,
        metro,
        simple,
        vertical,
        nowrap,
        'even-width': evenWidth
      }"
      class="r-ritm-tabs__list"
    >
      <li
        v-for="tab in tabs"
        :key="tab.componentOptions.propsData.id"
        class="list-item__header"
        :class="[
          tab.componentOptions.propsData.type,
          {
            active: tab.componentOptions.propsData.id === currentTab,
            metro
          }
        ]"
        :title="tab.componentOptions.propsData.title"
        @click="
          setActive(tab.componentOptions.propsData.id, { isManual: true })
        "
      >
        <r-icon
          v-if="tab.componentOptions.propsData.icon"
          :name="tab.componentOptions.propsData.icon"
          :size="18"
        />
        <span
          v-if="tab.componentOptions.propsData.tabName"
          class="list-item__header-name"
          :title="tab.componentOptions.propsData.tabName"
        >
          {{ tab.componentOptions.propsData.tabName }}
        </span>
      </li>
    </ul>
    <slot />
  </div>
</template>

<script setup>
import { provide, useSlots, watch, onMounted, computed } from 'vue'

// eslint-disable-next-line no-undef
const props = defineProps({
  value: {
    type: [String, Number],
    default: ''
  },
  metro: {
    type: Boolean,
    default: false
  },
  mini: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  indent: {
    type: Boolean,
    default: false
  },
  vertical: {
    type: Boolean,
    default: false
  },
  simple: {
    type: Boolean,
    default: false
  },
  evenWidth: {
    type: Boolean,
    default: false
  },
  nowrap: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: ''
  }
})

const slots = useSlots()

console.log(slots)

// eslint-disable-next-line no-undef
const $emit = defineEmits(['input'])

const tabs = computed(() =>
  slots
    .default()
    .filter(
      e =>
        e.componentOptions.propsData.tabName ||
        e.componentOptions.propsData.icon
    )
)

const currentTab = computed(() => props.value)

provide('tabId', currentTab)

watch(currentTab, value => {
  setActive(value, true)
})

onMounted(() => {
  if (props.value || props.value === 0) {
    setActive(props.value, { init: true })
  }
})

const setActive = (id, { init, isManual } = {}) => {
  if (currentTab.value === id) return
  !init && $emit('input', id, { isManual })
}
</script>

<style lang="scss">
.r-ritm-tabs {
  display: grid;
  grid-gap: 0.25rem;
  height: 100%;
  overflow: hidden;
  align-content: start;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.indent {
    .r-ritm-tabs__list-item {
      padding: 0.5rem !important;
    }
  }

  &__list {
    padding: 0.25rem;
    border-radius: $border-radius;
    display: grid;
    align-items: center;
    justify-items: stretch;
    width: 100%;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(40px, auto));
    justify-content: stretch;
    background-color: $bg-containers;

    &.even-width {
      grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
    }

    .list-item {
      &__header {
        height: 1.75rem;
        display: inline-grid;
        align-items: center;
        grid-auto-flow: column;
        grid-gap: 0.25rem;
        padding: 0 0.5rem;
        justify-content: center;
        border-radius: $border-radius;
        /* transition: ease 0.2s; */
        position: relative;
        transition: all 0.2s ease;

        &:not(:last-of-type):not(.metro) {
          &::after {
            content: '';
            position: absolute;
            right: calc(-0.25rem - 0.005rem);
            height: 70%;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0.2;
            width: 0.01rem;
            background: $dividers-low-contrast;
          }
        }

        &-name {
          color: $text-subhead;
          font-size: 14px;
          user-select: none;
          line-height: 1.75rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:empty {
            display: none;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: $field-hover-bg;
        }

        &.active {
          background-color: $accent-primary-1;

          &.success {
            background-color: $accent-success-bg;
          }

          &.warning {
            background-color: $accent-warning-bg;
          }

          &.danger {
            background-color: $accent-danger-bg;
          }
        }
      }
    }

    &.metro {
      padding: 0;
      grid-gap: 0.5rem;
      background-color: transparent;

      .list-item {
        &__header {
          height: 2.25rem;
          border-radius: 0;
          border-bottom: 2px solid transparent;

          &::after {
            content: '';
            position: absolute;
            right: -0.256rem;
            height: 90%;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0.6;
            width: 0.01rem;
            background: $dividers-low-contrast;
          }

          &::after {
            border: none;
          }

          &-name {
            font-size: 1rem;
          }

          &:hover {
            background-color: $field-hover-bg;
          }

          &.active {
            border-bottom-color: $accent-primary;
            background-color: $accent-primary-1;
          }
        }
      }
    }

    &.simple {
      background-color: transparent;
    }

    &.mini {
      padding: 0.2rem;

      .list-item {
        &__header {
          height: 1.5rem;
          grid-gap: 0.15rem;
        }
      }
    }

    &.vertical {
      grid-gap: 0.25rem;
      grid-template-columns: 1fr;

      .list-item {
        &__header {
          &::after {
            border: none;
          }
        }
      }
    }

    &.nowrap {
      grid-auto-flow: column;
    }
  }
}
</style>
