<template>
  <div
    class="r-range-picker"
    :class="{ error }"
  >
    <r-text
      v-if="label"
      color-type="subhead"
    >
      {{ label }}
    </r-text>
    <r-dropdown
      :disabled="disabled"
      :stick-to="stickTo"
      :menu-max-height="333"
      :show="showDD"
      @close="showDD = true"
    >
      <div
        class="r-range-picker__input"
        :class="{ disabled }"
      >
        <div class="r-range-picker__input-main">
          <r-icon name="date-calendar" />
          <r-text>{{ buttonTitle }}</r-text>
        </div>

        <r-button
          v-if="clearable && selectedValues.length > 0"
          icon="clear-input"
          simple
          mini
          tabindex="-1"
          @click.prevent.stop="clear"
        />
      </div>

      <div
        slot="dropdown-menu"
        class="r-range-picker__content"
      >
        <div
          v-if="showModel === 'year'"
          class="r-range-picker__header"
        >
          <r-button
            :disabled="disableBackBth"
            :icon="{ name: 'chevron-down', rotate: 90 }"
            mini
            simple
            @click="decrementYear"
          />

          <r-text
            v-if="selectedValues.length === 0 || step === 1"
            color-type="subhead"
          >
            {{ startDate }} - {{ endDate }}
          </r-text>

          <r-button
            v-else
            simple
            @click="handleBackButton"
          >
            {{ selectedValues[0].title }}
          </r-button>

          <r-button
            :disabled="disableForwardBth"
            :icon="{ name: 'chevron-down', rotate: -90 }"
            mini
            simple
            @click="incrementYear"
          />
        </div>
        <r-divider v-if="showModel === 'year'" />

        <div
          class="r-range-picker"
          :class="classList"
        >
          <r-button
            v-for="item in availableItems"
            :key="item.id"
            type="secondary"
            simple
            :year="item"
            :active="item.id === activeItem"
            @click="selectObject(item)"
          >
            {{ item.title }}
          </r-button>
        </div>
      </div>
    </r-dropdown>
  </div>
</template>

<script setup>
import i18n from '@/extensions/i18n'
import {
  ref,
  computed,
  watch,
  onMounted,
  defineAsyncComponent as DAC
} from 'vue'
import $store from 'STORE'

// eslint-disable-next-line no-undef
const emit = defineEmits(['change'])

// eslint-disable-next-line no-undef
const props = defineProps({
  type: {
    type: String,
    default: ''
  },
  startYear: {
    type: Number,
    default: null
  },
  endYear: {
    type: Number,
    default: null
  },
  value: {
    type: [String, Number, Object],
    default: null
  },
  label: {
    type: String,
    default: ''
  },
  stickTo: {
    type: String,
    default: 'left'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  clearable: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  }
})

const quarters = [
  { id: 1, title: i18n.t('quarter1'), type: 'quarter' },
  { id: 2, title: i18n.t('quarter2'), type: 'quarter' },
  { id: 3, title: i18n.t('quarter3'), type: 'quarter' },
  { id: 4, title: i18n.t('quarter4'), type: 'quarter' }
]

const locale = $store.getters.getLocale

const range = 12
const step = ref(1)
const showDD = ref(true)
const minYear = ref(null)
const maxYear = ref(null)
const selectedValues = ref([])
const selectedObject = ref(null)
const currentYear = ref(new Date().getFullYear())

const averageYear = computed(() => {
  const range = props.endYear - currentYear.value

  if (range < 6 && range > 0) {
    return props.endYear - 6
  } else {
    return currentYear.value
  }
})

const propsYear = computed(() => props.value)

const dateFrom = computed(() =>
  props.startYear ? props.startYear : averageYear.value - 500
)

const dateTo = computed(() =>
  props.endYear ? props.endYear : averageYear.value + 500
)

const disableForwardBth = computed(
  () =>
    showModel.value !== 'year' ||
    (step.value === 1 && maxYear.value >= dateTo.value)
)

const disableBackBth = computed(
  () =>
    showModel.value !== 'year' ||
    (step.value === 1 && dateFrom.value >= minYear.value)
)

const showModel = computed(() => {
  if (props.type === 'year-month' || props.type === 'year-quarter') {
    return 'year'
  } else {
    return props.type
  }
})

const buttonTitle = computed(() => {
  if (selectedValues.value.length > 0) {
    return selectedValues.value
      .reduce((acc, curr) => {
        if (curr.type === 'month') {
          acc.push(availableMonths.value.find(m => m.id === curr.id).title)
        } else if (curr.type === 'quarter') {
          acc.push(quarters.find(m => m.id === curr.id).title)
        } else {
          acc.push(curr.title)
        }

        return acc
      }, [])
      .reverse()
      .join(', ')
  } else if (selectedObject.value) {
    if (props.type === 'days') {
      return availableDays.value.find(day => day.id === selectedObject.value)
        .title
    } else if (props.type === 'month') {
      return availableMonths.value.find(m => m.id === selectedObject.value)
        .title
    } else if (props.type === 'quarter') {
      return quarters.find(m => m.id === selectedObject.value).title
    } else if (props.type === 'year') {
      return selectedObject.value
    } else {
      return i18n.t('not-selected')
    }
  } else {
    return i18n.t('not-selected')
  }
})

const activeItem = computed(() => {
  if (props.type === 'year-month' || props.type === 'year-quarter') {
    if (step.value === 2) {
      return selectedValues.value[1]?.id
    } else {
      return selectedValues.value[0]?.id
    }
  } else {
    return selectedObject.value
  }
})

const startDate = computed(() =>
  minYear.value >= dateFrom.value ? minYear.value : dateFrom.value
)

const endDate = computed(() => {
  if (props.endYear) {
    return maxYear.value <= dateTo.value ? maxYear.value : dateTo.value
  } else {
    return maxYear.value
  }
})

const availableMonths = computed(() => {
  const dateFormatter = new Intl.DateTimeFormat(locale, { month: 'long' })

  const months = []
  for (let month = 0; month < 12; month++) {
    const date = new Date(2023, month, 1)
    const monthName = dateFormatter.format(date)
    const formattedName = monthName.charAt(0).toUpperCase() + monthName.slice(1)
    months.push({ id: month + 1, title: formattedName, type: 'month' })
  }

  return months
})

const availableDays = computed(() => {
  const dateFormatter = new Intl.DateTimeFormat(locale, { weekday: 'short' })

  const days = []
  for (let day = 0; day < 7; day++) {
    const date = new Date(2023, 12, day)
    const dayName = dateFormatter.format(date)
    days.push({ id: day, title: dayName.toUpperCase() })
  }

  return days
})

const availableYears = computed(() => {
  const years = []
  for (let year = startDate.value; year <= endDate.value; year++) {
    years.push({ id: year, title: String(year), type: 'year' })
  }

  return years
})

const availableItems = computed(() => {
  let result = null

  if (showModel.value === 'year' && step.value === 1) {
    result = availableYears.value
  } else if (
    showModel.value === 'month' ||
    (props.type === 'year-month' && step.value === 2)
  ) {
    result = availableMonths.value
  } else if (
    showModel.value === 'quarter' ||
    (props.type === 'year-quarter' && step.value === 2)
  ) {
    result = quarters
  } else if (showModel.value === 'days') {
    result = availableDays.value
  }

  return result
})

const classList = computed(() => {
  let result = null

  if (showModel.value === 'year' && step.value === 1) {
    result = 'year-list'
  } else if (
    showModel.value === 'month' ||
    (props.type === 'year-month' && step.value === 2)
  ) {
    result = 'month-list'
  } else if (
    showModel.value === 'quarter' ||
    (props.type === 'year-quarter' && step.value === 2)
  ) {
    result = 'quarter-list'
  } else if (showModel.value === 'days') {
    result = 'days-list'
  }

  return result
})

const selectObject = obj => {
  selectedObject.value = obj
  const index = selectedValues.value.findIndex(item => item.type === obj.type)

  if (index !== -1) {
    selectedValues.value.splice(index, 1, obj)
  } else {
    selectedValues.value.push(obj)
  }

  if (step.value === 2) {
    showDD.value = false
  }

  if (props.type === 'year-month' || props.type === 'year-quarter') {
    step.value = 2
  } else {
    showDD.value = false
  }
}

const handleBackButton = () => {
  if (showModel.value === 'year' && step.value === 1) selectedValues.value = []

  if (showModel.value === 'year' && step.value === 2) step.value = 1
}

const changeYear = delta => {
  const newYear = selectedValues.value[0].id + delta

  if (newYear >= startDate.value && newYear <= endDate.value) {
    selectedObject.value = availableYears.value.find(
      year => year.id === newYear
    )
    const index = selectedValues.value.findIndex(
      item => item.type === selectedObject.value.type
    )

    if (index !== -1) {
      selectedValues.value.splice(index, 1, selectedObject.value)
    }
  }
}

const incrementYear = () => {
  if (selectedValues.value.length > 0 && step.value === 2) {
    changeYear(1)
  } else {
    minYear.value += range
    maxYear.value += range
  }
}

const decrementYear = () => {
  if (selectedValues.value.length > 0 && step.value === 2) {
    changeYear(-1)
  } else {
    minYear.value -= range
    maxYear.value -= range
  }
}

const clear = () => {
  selectedValues.value = []
  step.value = 1
}

const init = () => {
  let middleRangeYear

  if (props.value && props.type === 'year') {
    middleRangeYear = props.value
  } else {
    middleRangeYear = averageYear.value
  }

  minYear.value = middleRangeYear - 5
  maxYear.value = middleRangeYear + 6

  for (const type in props.value) {
    selectObject({ id: props.value[type], type, title: props.value[type] })
  }
}

watch(propsYear, init, { immediate: true })

watch(
  selectedValues,
  () => {
    let result

    if (props.type === 'year-month' || props.type === 'year-quarter') {
      const formattedDate = {}

      for (const item of selectedValues.value) {
        formattedDate[item.type] = item.id
      }

      result = formattedDate
    } else {
      result = selectedValues.value.length ? selectedValues.value[0].id : null
    }

    emit('change', result)
  },
  { deep: true }
)

onMounted(init)
</script>

<style lang="scss">
.r-range-picker {
  min-width: 170px;
  width: 100%;
  display: grid;
  grid-gap: 0.25rem 0.5rem;
  align-items: center;

  &__input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 170px;
    height: 36px;
    border: 1px solid $field-border;
    gap: 0.25rem;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    background: $field-bg;
    cursor: pointer;

    &-main {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.25rem;
    }
  }

  &__content {
    padding: 0.5rem;
    @include grid-column;
  }

  &__header {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
    align-items: center;
  }
}

.error {
  .r-range-picker__input {
    border-color: $accent-danger;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.year-list {
  @include grid-column;
  grid-gap: 0.25rem;
  grid-template-columns: repeat(4, 1fr);
}

.month-list {
  @include grid-column;
  grid-gap: 0.25rem;
  grid-template-columns: repeat(3, 1fr);
}

.quarter-list {
  @include grid-column;
  grid-gap: 0.25rem;
  grid-template-columns: repeat(2, 1fr);
}

.days-list {
  display: flex;
  width: 170px;
  justify-content: center;
  flex-wrap: wrap;
}

.active-item {
  color: $white;
  background: $accent-primary;
}
</style>
