var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.isVisible)?_c('div',{ref:"container",staticClass:"r-pagination-new"},[(_setup.hasControl('prev'))?_c('div',{staticClass:"r-pagination-new__controls"},[_c('r-button',{staticClass:"r-pagination-new__arrow",attrs:{"simple":"","mini":_vm.small,"tiny-icon":_vm.small,"icon":{
        name: 'double-chevron-down',
        rotate: 90
      },"disabled":_setup.firstActive},on:{"click":_setup.goFirst}}),_c('r-button',{staticClass:"r-pagination-new__arrow",attrs:{"simple":"","mini":_vm.small,"tiny-icon":_vm.small,"icon":{
        name: 'chevron-down',
        rotate: 90
      },"disabled":_setup.firstActive},on:{"click":function($event){return _setup.stepTo(-1)}}})],1):_vm._e(),(_setup.hasControl('pager'))?_c('div',{staticClass:"r-pagination-new__controls"},_vm._l((_setup.pages),function(pageNumber){return _c('r-button',{key:pageNumber,staticClass:"r-pagination-new__page-number",attrs:{"type":"secondary","mini":_vm.small,"active":_setup.isActive(pageNumber),"simple":!_setup.isActive(pageNumber)},on:{"click":function($event){return _setup.onPageChange(pageNumber)}}},[_vm._v(" "+_vm._s(pageNumber)+" ")])}),1):_vm._e(),(_setup.hasControl('next'))?_c('div',{staticClass:"r-pagination-new__controls"},[_c('r-button',{staticClass:"r-pagination-new__arrow",attrs:{"simple":"","mini":_vm.small,"tiny-icon":_vm.small,"icon":{
        name: 'chevron-down',
        rotate: -90
      },"disabled":_setup.lastActive},on:{"click":function($event){return _setup.stepTo(1)}}}),_c('r-button',{staticClass:"r-pagination-new__arrow",attrs:{"simple":"","mini":_vm.small,"tiny-icon":_vm.small,"icon":{
        name: 'double-chevron-down',
        rotate: -90
      },"disabled":_setup.lastActive},on:{"click":_setup.goLast}})],1):_vm._e(),(_setup.hasControl('jumper'))?_c('div',{staticClass:"r-pagination-new__controls jumper"},[_c('r-text',{attrs:{"color-type":"secondary","no-wrap":""}},[_vm._v(" "+_vm._s(_vm.$t('go-to'))+" ")]),_c('r-input-number',{staticClass:"r-pagination-new__jumper-input",attrs:{"value":_setup.currentPage,"max":_setup.totalPages,"mini":"","no-controls":""},on:{"input":_setup.onInput,"blur":_setup.setFromInput,"enter":_setup.setFromInput}})],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }