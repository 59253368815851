import {
  GET_DT_ROOT_ENDPOINT,
  GET_TILESERVER_ROOT_ENDPOINT
} from '@/api/modeling'
import { GET_MODELING_ROOT_ENDPOINT } from '@/api/modeling'

export default (beforeEnter, wrapper) => {
  return {
    name: 'forecast',
    path: 'forecast',
    beforeEnter,
    component: wrapper,
    redirect: 'explorer',
    meta: {
      title: 'forecast:title',
      icon: 'tune-component',
      description: 'forecast:desc'
    },
    children: [
      {
        name: 'digital-twin',
        path: 'digital-twin',
        beforeEnter,
        component: () => import('@/modules/modeling/wrapper'),
        redirect: 'digital-twin/dt-selector',
        meta: {
          title: 'dt:title',
          abbr: 'dt:abbr-title',
          icon: 'edit-geometry',
          description: 'dt:desc',
          endpoints: [GET_DT_ROOT_ENDPOINT()],
          projects: [
            'habarovsk_akadem',
            'habarovsk_tpim',
            'gbu_bdd_kazan',
            'rostov_on_don',
            'spb_politekh',
            'vladikavkaz',
            'rostov_tmp',
            'cheboksary',
            'vladimir',
            'academic',
            'loadtest',
            'spb_gasu',
            'avtodor',
            'labgrad',
            'cpt_spb',
            'kubgtu',
            'irano',
            'urgups',
            'uunit',
            'niipi',
            'eputs',
            'tomsk',
            'vshe',
            'demo',
            'urfu',
            'lgtu',
            'guap',
            'madi',
            'omsk',
            'kdh',
            'ctt',
            'vtm',
            'tiu'
          ]
        },
        children: [
          {
            name: 'dt-selector',
            path: 'dt-selector',
            beforeEnter,
            component: () => import('@/modules/modeling/dt/views/dt-selector'),
            meta: {
              title: 'dt:title',
              subtitle: 'dt:select-model',
              abbr: 'dt:abbr-title',
              icon: 'edit-geometry',
              description: 'dt:desc',
              isModelSelector: true
            }
          },
          {
            name: 'dt-main',
            path: 'dt-main',
            beforeEnter,
            component: () => import('@/modules/modeling/dt/views/digital-twin'),
            meta: {
              title: 'dt:title',
              icon: 'edit-geometry',
              abbr: 'dt:abbr-title',
              subtitle: 'dt:it-subtitle',
              description: 'dt:desc',
              isMap: true
            }
          },
          {
            name: 'pt-editor',
            path: 'pt-editor',
            beforeEnter,
            component: () => import('@/modules/modeling/dt/views/dt-pt'),
            meta: {
              subtitle: 'dt:pt-subtitle',
              title: 'dt:title',
              abbr: 'dt:abbr-title',
              icon: 'edit-geometry',
              description: 'dt:desc',
              isMap: true
            }
          },
          {
            name: 'dt-book',
            path: 'book',
            beforeEnter,
            component: () => import('@/modules/modeling/dt/views/dt-book'),
            meta: {
              title: 'dt:title',
              subtitle: 'dt:model-book',
              abbr: 'dt:abbr-title',
              icon: 'edit-geometry',
              description: 'dt:desc'
            }
          },
          {
            name: 'schedule-editor',
            path: 'schedule-editor',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/dt/views/dt-schedule-editor'),
            meta: {
              title: 'dt:title',
              subtitle: 'dt:schedule-editor',
              icon: 'edit-geometry',
              abbr: 'dt:abbr-title',
              endpoints: [
                GET_DT_ROOT_ENDPOINT(),
                GET_TILESERVER_ROOT_ENDPOINT()
              ]
            }
          },
          {
            name: 'dt-network-check',
            path: 'network-check',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/dt/views/dt-network-check'),
            meta: {
              title: 'dt:title',
              subtitle: 'dt:network-check',
              icon: 'calculator',
              abbr: 'dt:abbr-title',
              endpoints: [
                GET_DT_ROOT_ENDPOINT(),
                GET_TILESERVER_ROOT_ENDPOINT()
              ],
              isMap: true
            }
          },
          {
            name: 'dt-isochrones',
            path: 'isochrones',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/dt/views/dt-isochrones.vue'),
            meta: {
              title: 'dt:title',
              abbr: 'dt:abbr-title',
              subtitle: 'modeling:isochrones',
              icon: 'node-layer',
              endpoints: [
                GET_DT_ROOT_ENDPOINT(),
                GET_TILESERVER_ROOT_ENDPOINT()
              ],
              isMap: true
            }
          },
          {
            name: 'dt-route-search',
            path: 'route-search',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/dt/views/dt-route-search.vue'),
            meta: {
              subtitle: 'modeling:route-search',
              icon: 'route',
              title: 'dt:title',
              abbr: 'dt:abbr-title',
              endpoints: [
                GET_DT_ROOT_ENDPOINT(),
                GET_TILESERVER_ROOT_ENDPOINT()
              ],
              isMap: true
            }
          },
          {
            name: 'time-profile-editor',
            path: 'time-profile-editor',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/dt/views/dt-time-profile-editor'),
            meta: {
              title: 'dt:title',
              subtitle: 'dt:time-profile-editor',
              icon: 'edit',
              abbr: 'dt:abbr-title',
              endpoints: [
                GET_DT_ROOT_ENDPOINT(),
                GET_TILESERVER_ROOT_ENDPOINT()
              ]
            }
          }
        ]
      },
      {
        name: 'modeling',
        path: 'modeling',
        beforeEnter,
        component: () => import('@/modules/modeling/wrapper'),
        redirect: 'modeling/selector',
        meta: {
          title: 'modeling:title',
          abbr: 'modeling:abbr-title',
          icon: 'calculator',
          description: 'modeling:desc',
          endpoints: [GET_MODELING_ROOT_ENDPOINT()],
          projects: [
            'habarovsk_akadem',
            'habarovsk_tpim',
            'gbu_bdd_kazan',
            'rostov_on_don',
            'spb_politekh',
            'vladikavkaz',
            'cheboksary',
            'rostov_tmp',
            'vladimir',
            'academic',
            'tashkent',
            'loadtest',
            'spb_gasu',
            'labgrad',
            'avtodor',
            'cpt_spb',
            'kubgtu',
            'irano',
            'urgups',
            'tomsk',
            'uunit',
            'niipi',
            'eputs',
            'vshe',
            'demo',
            'lgtu',
            'urfu',
            'madi',
            'guap',
            'omsk',
            'kdh',
            'ctt',
            'vtm',
            'tiu'
          ]
        },
        children: [
          {
            name: 'selector',
            path: 'selector',
            beforeEnter,
            component: () =>
              import(
                '@/modules/modeling/sub-modules/model-selector/components/model-selector'
              ),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:selector-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc',
              isModelSelector: true
            }
          },
          {
            name: 'model-view',
            path: 'model-view',
            beforeEnter,
            component: () => import('@/modules/modeling/tpm/views/tpm'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:view-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc',
              isMap: true
            }
          },
          {
            name: 'modeling-scenario',
            path: 'scenario/:id',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/tpm/views/tpm-calculated-scenario'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:modeling-scenario-view',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              isMap: true
            }
          },
          {
            name: 'scenario-comparison',
            path: 'scenario-comparison/:id',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/tpm/views/tpm-scenario-comparison'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:modeling-scenario-comparison',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              isMap: true
            }
          },
          {
            name: 'matrices',
            path: 'matrices',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/tpm/views/tpm-matrices'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:matrices-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc'
            }
          },
          {
            name: 'modifications',
            path: 'modifications',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/tpm/views/tpm-modifications'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modifications',
              abbr: 'modeling:abbr-title',
              icon: 'tune',
              description: 'modeling:desc'
            }
          },
          {
            name: 'procedures',
            path: 'procedures',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/tpm/views/tpm-procedures'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:procedures-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc'
            }
          },
          {
            name: 'procedures-settings',
            path: 'procedures-settings',
            beforeEnter,
            component: () =>
              import(
                '@/modules/modeling/tpm/views/tpm-procedures-common-settings'
              ),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:common-procedure-settings',
              abbr: 'modeling:abbr-title',
              icon: 'settings'
            }
          },
          {
            name: 'book',
            path: 'book',
            beforeEnter,
            component: () => import('@/modules/modeling/tpm/views/tpm-book'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:book-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc'
            }
          },
          {
            name: 'optimization-calc',
            path: 'optimization-calc',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/tpm/views/tpm-optimization-calc'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:oc-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc',
              isMap: true
            }
          },
          {
            name: 'modeling-network-check',
            path: 'network-check',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/tpm/views/tpm-network-check'),
            meta: {
              abbr: 'modeling:abbr-title',
              title: 'modeling:title',
              subtitle: 'dt:network-check',
              icon: 'calculator',
              endpoints: ['modeling'],
              isMap: true
            }
          },

          {
            name: 'modeling-isochrones',
            path: 'isochrones',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/tpm/views/tpm-isochrones.vue'),
            meta: {
              abbr: 'modeling:abbr-title',
              title: 'modeling:title',
              subtitle: 'modeling:isochrones',
              icon: 'node-layer',
              endpoints: ['modeling'],
              isMap: true
            }
          },
          {
            name: 'modeling-route-search',
            path: 'route-search',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/tpm/views/tpm-route-search.vue'),
            meta: {
              subtitle: 'modeling:route-search',
              title: 'modeling:title',
              abbr: 'modeling:abbr-title',
              icon: 'route',
              endpoints: ['modeling'],
              isMap: true
            }
          },
          {
            name: 'micromodeling-selector',
            path: 'micromodeling-selector',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/tpm/views/tpm-micromodeling-selector'),
            meta: {
              subtitle: 'modeling:micromodeling',
              title: 'modeling:title',
              abbr: 'modeling:abbr-title',
              icon: 'map',
              endpoints: ['modeling'],
              isMap: true
            }
          },
          {
            name: 'micromodeling',
            path: 'micromodeling',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/tpm/views/tpm-micromodeling'),
            meta: {
              subtitle: 'modeling:micromodeling',
              title: 'modeling:title',
              abbr: 'modeling:abbr-title',
              icon: 'map',
              endpoints: ['modeling'],
              isMap: true
            }
          }
        ]
      },
      {
        name: 'logistic-modeling',
        path: 'logistic-modeling',
        beforeEnter,
        component: () => import('@/modules/modeling/wrapper'),
        redirect: 'logistic-modeling/selector',
        meta: {
          title: 'logistic-modeling:title',
          abbr: 'logistic-modeling:abbr-title',
          icon: 'ship-hollow',
          description: 'logistic-modeling:desc',
          endpoints: ['simlog'],
          projects: ['mgu', 'eputs']
        },
        children: [
          {
            name: 'logistic-modeling-selector',
            path: 'selector',
            beforeEnter,
            component: () =>
              import(
                '@/modules/modeling/logistic-modeling/views/logistic-modeling-selector'
              ),
            meta: {
              title: 'logistic-modeling:title',
              subtitle: 'modeling:selector-subtitle',
              abbr: 'logistic-modeling:abbr-title',
              icon: 'calculator',
              description: 'logistic-modeling:desc',
              isModelSelector: true,
              noSessions: true
            }
          },
          {
            name: 'logistic-modeling-main',
            path: 'main',
            beforeEnter,
            component: () =>
              import(
                '@/modules/modeling/logistic-modeling/views/logistic-modeling'
              ),
            meta: {
              title: 'logistic-modeling:title',
              subtitle: 'modeling:view-subtitle',
              abbr: 'logistic-modeling:abbr-title',
              icon: 'calculator',
              description: 'logistic-modeling:desc',
              isMap: true,
              noSessions: true
            }
          },
          {
            name: 'logistic-modeling-book',
            path: 'book',
            beforeEnter,
            component: () =>
              import(
                '@/modules/modeling/logistic-modeling/views/logistic-modeling-book'
              ),
            meta: {
              title: 'logistic-modeling:title',
              subtitle: 'logistic-modeling:book-subtitle',
              abbr: 'logistic-modeling:abbr-title',
              icon: 'calculator',
              description: 'logistic-modeling:desc',
              noSessions: true
            }
          }
        ]
      }
    ]
  }
}
